import React from "react";
import { motion, Reorder } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPython,
  faJava,
  faJsSquare,
  faReact,
  faNodeJs,
  faDocker,
  faGitAlt,
  faAndroid,
} from "@fortawesome/free-brands-svg-icons";
import { faDatabase, faCogs, faCode } from "@fortawesome/free-solid-svg-icons";

const programmingLanguages = [
  "Python",
  "Java",
  "C/C++",
  "JavaScript",
  "Typescript",
  "SQL",
  "Kotlin",
];

const frameworks = ["Flask", "React", "Vue", "Next", "Node", "Tailwind CSS"];

const developerTools = [
  "Git",
  "Github",
  "Docker",
  "Kubernetes",
  "GitLab CI/CD",
  "Android Studio",
  "Selenium",
  "JUnit",
];

const technologies = [
  "Firebase",
  "MongoDB",
  "PostgreSQL",
  "Tensorflow",
  "Keras",
  "OpenCV",
  "Pandas",
  "NumPy",
  "Matplotlib",
];

const skillToIcon = {
  Python: faPython,
  Java: faJava,
  "C/C++": faCogs,
  JavaScript: faJsSquare,
  Typescript: faJsSquare,
  SQL: faDatabase,
  Kotlin: faAndroid,
  Flask: faCode,
  React: faReact,
  Vue: faCode,
  Next: faCode,
  Node: faNodeJs,
  "Tailwind CSS": faCode,
  Git: faGitAlt,
  Github: faGitAlt,
  Docker: faDocker,
  Kubernetes: faCode,
  "GitLab CI/CD": faCode,
  "Android Studio": faAndroid,
  Selenium: faCode,
  JUnit: faCode,
  Firebase: faDatabase,
  MongoDB: faDatabase,
  PostgreSQL: faDatabase,
  Tensorflow: faCode,
  Keras: faCode,
  OpenCV: faCode,
  Pandas: faCode,
  NumPy: faCode,
  Matplotlib: faCode,
};

const fadeInAnimationVartients = {
  initial: {
    opacity: 0,
    y: 0,
  },
  animate: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.15 * index,
    },
  }),
};

const renderSkills = (skills) => (
  <div className="flex flex-wrap justify-center gap-4 p-4">
    {skills.map((skill, index) => (
      <motion.div
        key={index}
        initial="initial"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        variants={fadeInAnimationVartients}
        transition={{ type: "spring", stiffness: 300 }}
        className="w-10 bg-[#FEFAF6] font-bold text-lg min-w-[140px] p-3 rounded-lg shadow-md flex justify-center items-center"
        whileInView="animate"
        viewport={{
          once: true,
        }}
        custom={index}
      >
        <FontAwesomeIcon icon={skillToIcon[skill] || faCode} className="mr-2" />
        <span className="text-lg text-gray-800">{skill}</span>
      </motion.div>
    ))}
  </div>
);

function Skills() {
  return (
    <div className="flex flex-col items-center justify-start w-full h-auto p-5">
      <h2 className="mb-8 text-6xl font-bold text-white">
        My <span className="text-indigo-700">Technical</span> Skills
      </h2>

      <div className="w-full max-w-7xl">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2">
          <div>
            <h3 className="flex justify-center mb-4 text-2xl font-semibold text-white">
              Programming Languages
            </h3>
            <hr className="w-11/12 mx-auto mb-4 border-t-2 border-gray-300 md:w-3/4" />
            {renderSkills(programmingLanguages)}
          </div>
          <div>
            <h3 className="flex justify-center mb-4 text-2xl font-semibold text-white">
              Frameworks
            </h3>
            <hr className="w-11/12 mx-auto mb-4 border-t-2 border-gray-300 md:w-3/4" />
            {renderSkills(frameworks)}
          </div>
          <div>
            <h3 className="flex justify-center mb-4 text-2xl font-semibold text-white">
              Developer Tools
            </h3>
            <hr className="w-11/12 mx-auto mb-4 border-t-2 border-gray-300 md:w-3/4" />
            {renderSkills(developerTools)}
          </div>
          <div>
            <h3 className="flex justify-center mb-4 text-2xl font-semibold text-white">
              Technologies
            </h3>
            <hr className="w-11/12 mx-auto mb-4 border-t-2 border-gray-300 md:w-3/4" />
            {renderSkills(technologies)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
