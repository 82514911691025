import React from "react";
import myphoto from "../Images/my_photo1.jpg";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const About = () => {
  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  const slideVariants = {
    hidden: { x: 0, width: "100%" },
    visible: {
      x: "100%",
      width: "0%",
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  const controls = useAnimation();
  const controls1 = useAnimation();
  const Slidecontrols = useAnimation();
  const Slidecontrols1 = useAnimation();
  const [ref, inView] = useInView();
  const [refDesc, inViewDesc] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
      Slidecontrols.start("visible");
    }
    if (inViewDesc) {
      controls1.start("visible");
      Slidecontrols1.start("visible");
    }
  }, [controls, inView, inViewDesc, Slidecontrols, Slidecontrols1]);

  return (
    <section
      id="About"
      className="flex min-h-screen p-10 md:pl-28 md:pr-28 items-middle md:flex-row md:items-center"
    >
      <div className="flex flex-col">
        <div className="relative flex justify-center overflow-hidden">
          <div className="relative inline-block md:pb-14">
            <motion.h1
              className="flex justify-center mb-4 text-6xl font-bold text-indigo-700 md:text-7xl"
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={variants}
              transition={{ duration: 0.25, delay: 0.3 }}
            >
              <div>
                <h1 className="flex justify-center text-6xl text-white">
                  Get to know
                </h1>
                About Me
              </div>
            </motion.h1>
            <motion.div
              initial="hidden"
              animate={Slidecontrols}
              variants={slideVariants}
              className="absolute top-0 bottom-0 left-0 "
            ></motion.div>
          </div>
        </div>
        <div className="flex flex-row">
          <img
            src={myphoto}
            className="justify-center hidden transition-transform duration-300 ease-in-out rounded-lg shadow-lg lg:flex-col md:flex-col lg:flex h-1/2 md:h-1/4 md:w-80 hover:scale-110"
            alt="Description of image"
          />
          <div style={{ position: "relative", overflow: "hidden" }}>
            <motion.p
              ref={refDesc}
              initial="hidden"
              animate={controls}
              variants={variants}
              transition={{ duration: 0.25, delay: 0.3 }}
              className="mb-6 text-3xl text-left text-white md:text-3xl lg:pl-5 lg:text-2xl"
            >
              I'm Sriman Ujhanthachhen, a junior majoring in Computer Science at
              <br></br> San Jose State University, with a passion in backend
              development. I am passionate about software development and am
              always looking for new opportunities to learn and grow. I’m
              currently exploring technologies like LangChain, Pinecone, and
              Next.js to build AI-powered applications. When I’m not coding,
              you’ll find me experimenting with new AI models or contributing to
              open-source projects. I'm eager to connect with peers and
              professionals to explore potential collaborations or employment
              opportunities.
            </motion.p>

            <motion.dev
              variants={{
                hidden: { left: 0 },
                visible: { left: "100%" },
              }}
              initial="hidden"
              animate={Slidecontrols1}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              className="absolute left-0 right-0 top-4 bottom-4"
            ></motion.dev>

            <div className="relative">
              <div className="relative inline-block ">
                <motion.p
                  className="font-semibold text-gray-300 lg:text-2xl lg:pl-5"
                  ref={refDesc}
                  initial="hidden"
                  animate={controls}
                  variants={variants}
                  transition={{ duration: 0.25, delay: 0.3 }}
                >
                  Feel free to reach out via{" "}
                  <a
                    href="mailto:srimanp201@gmail.com"
                    className="text-blue-500 hover:text-blue-700"
                  >
                    email
                  </a>{" "}
                  or connect with me on{" "}
                  <a
                    href="https://www.linkedin.com/in/srimanujhanthachhen/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:text-blue-700"
                  >
                    LinkedIn
                  </a>
                </motion.p>
                <motion.div
                  initial="hidden"
                  animate={Slidecontrols1}
                  variants={slideVariants}
                  className="absolute top-0 bottom-0 left-0"
                ></motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
