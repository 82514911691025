import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import RoutesWithAnimation from "./components/AnimatedRoutes";
import { AnimatePresence } from "framer-motion";
import Footer from "./pages/Footer.js";
import "./App.css";
import Helmet from "react-helmet";
import Navigation from "./nav/nav";
import { motion } from "framer-motion";
import AnimatedCursor from "react-animated-cursor";
import logo from "./svg/logo.svg";
function App() {
  return (
    <div className="bg-black App font-poppins">
      <Helmet>
        <title>Sriman Ujhanthachhen</title>
      </Helmet>
      <Navigation />
      <Footer />
    </div>
  );
}

export default App;
