import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-scroll";

function AnimatedButton({
  button_name,
  button_icon,
  button_link = "",
  isLink = false,
}) {
  const handleClick = () => {
    if (button_link) {
      window.open(
        //"https://drive.google.com/file/d/1kdw77VGUs9Wxq0XHl2VFGE7LlzOAUxwD/view?usp=sharing",
        button_link,
        "_blank"
      );
    } else {
      console.log("button_link", button_link);
      <Link to={button_link} spy={true} smooth={true} duration={500}></Link>;
    }
  };

  return (
    <motion.button
      className="flex items-center px-4 py-2 font-bold text-gray-300 transition duration-500 ease-in-out transform border-2 border-white text-l lg:text-xl rounded-3xl bg-gradient-to-r hover:-translate-y-1 hover:scale-110"
      onClick={button_link && !isLink ? handleClick : null}
      whileHover={{
        scale: 1.2,
        textShadow: "0px 0px 8px rgb(255,255,255)",
        boxShadow: "0px 0px 8px rgb(255,255,255)",
      }}
    >
      <FontAwesomeIcon icon={button_icon} className="mr-2" />
      {isLink ? (
        <Link to={button_link} spy={true} smooth={true} duration={500}>
          {button_name}
        </Link>
      ) : (
        button_name
      )}
    </motion.button>
  );
}
export default AnimatedButton;
