import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <div className="flex justify-center pt-32 bg-gradient-to-b from-black to-gray-800">
      <footer className="flex flex-col items-center justify-around w-full md:flex-row">
        <h1 className="text-lg font-medium md:p-5 text-gray-50">
          © 2024 Sriman Ujhanthachhen
        </h1>
        {/* <div className="flex items-center p-5">
          <FontAwesomeIcon
            icon={faPhone}
            className="mr-2 text-lg text-gray-50"
          />
          <a
            href="tel:+18314007165"
            className="text-lg font-medium text-gray-50"
          >
            {" "}
            (831) 400-7165
          </a>
        </div> */}
        <div className="flex items-center md:p-5">
          <FontAwesomeIcon
            icon={faEnvelope}
            className="mr-2 text-lg text-gray-50"
          />
          <a
            href="mailto:srimanp201@gmail.com"
            className="text-lg font-medium text-gray-50"
          >
            {" "}
            srimanp201@gmail.com
          </a>
        </div>
        <div className="flex items-center gap-5 p-5">
          <a
            href="https://facebook.com/yourprofile"
            className="mr-2 text-gray-50"
          >
            <FontAwesomeIcon icon={faFacebook} className="text-xl" />
          </a>
          <a
            href="https://twitter.com/srimanp201"
            className="mr-2 text-gray-50"
          >
            <FontAwesomeIcon icon={faTwitter} className="text-xl" />
          </a>
          <a
            href="https://www.linkedin.com/in/srimanujhanthachhen/"
            className="text-gray-50"
          >
            <FontAwesomeIcon icon={faLinkedin} className="text-xl" />
          </a>
          <a href="https://github.com/SrimanCode" className="text-gray-50">
            <FontAwesomeIcon icon={faGithub} className="text-xl" />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
