import React, { useState } from "react";
import { motion } from "framer-motion";
import settleinn from "../Images/settleinn.png";
import footnow from "../Images/footnow.png";
import sleeptracker from "../Images/sleepTracker.png";
import sudokusolver from "../Images/Sudoku_solver.png";
import followup from "../Images/followup.png";
import snakegame from "../Images/snakeGame.png";
import pathfinding from "../Images/pathfinding.png";
import BookCompass from "../Images//bookcompassDash.png";
import chatapp from "../Images/chat.png";
import { AnimatePresence } from "framer-motion";
import NeuraLearn from "../Images/NeuraLearn.png";
import AnimatedButton from "./AnimatedButton";
import QuizzAI from "../Images/Quizz.png";
import profspot from "../Images/ProfSpot.png";

import {
  faReact,
  faNodeJs,
  faJsSquare,
  faCss3Alt,
  faHtml5,
  faPython,
  faAndroid,
  faGithub,
  firebase,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const techToIcon = {
  React: faReact,
  "Next.js": faReact,
  "Node.js": faNodeJs,
  "Tailwind CSS": faCss3Alt,
  HTML: faHtml5,
  CSS: faCss3Alt,
  JS: faJsSquare,
  Python: faPython,
  "Android Studio": faAndroid,
  Git: faGithub,
};
const projects = {
  web: [
    {
      name: "ProfSpot",
      description:
        "ProfSpot is a RateMyProfessor chatbot powered by Retrieval-Augmented Generation (RAG) technology. It allows students to interactively search for professor ratings and reviews by leveraging advanced AI techniques and a scalable vector database.",
      image: profspot,
      link: "https://github.com/SrimanCode/HEADSTARTERTEAM-AiRateMyProfessor",
      demo: "https://headstarterteam-ai-rate-my-professor.vercel.app/",
      tech: [
        "React",
        "Next",
        "Node.js",
        "Material-UI",
        "Pinecone",
        "OpenAI API",
      ],
    },
    {
      name: "QuizzAI",
      description:
        "QuizzAI provides a dynamic and interactive platform for mastering knowledge through customizable quizzes and flashcards. With intuitive AI-assisted features, it personalizes learning to enhance understanding and retention, making study sessions both effective and engaging.",
      image: QuizzAI,
      link: "https://github.com/ricozama121803/HeadstarterTeam-flashcard-saas",
      tech: [
        "Next.js",
        "Node.js",
        "Firebase",
        "Material-UI",
        "SAAS",
        "AWS",
        "OpenAI",
      ],
    },
    {
      name: "NeuraLearnAI",
      description:
        "NeuraLearnAI is a chatbot designed to help students learn AI and ML concepts. It uses a Retrieval-Augmented Generation (RAG) model to deliver personalized, context-aware interactions, adapting its teaching style to each student's needs.",
      image: NeuraLearn,
      link: "https://github.com/HeadStarterTeam/AI-Customer-Support",
      tech: ["Next.js", "Node.js", "Firebase", "Pinecone", "Material-UI"],
    },
    {
      name: "BookCompass",
      description:
        "BookCompass is full stack web app that offering a vast library of books paired with personalized recommendations to suit your reading tastes.",
      image: BookCompass,
      link: "https://github.com/SrimanCode/Mylibrary",
      tech: [
        "React",
        "Node.js",
        "MySQL",
        "OPEN Library API",
        "Tailwind CSS",
        "JWT Authentication",
      ],
    },

    {
      name: "Sudoku Solver",
      description:
        "Sudoku Solver is a web application that allows you to solve Sudoku puzzles. It uses a backtracking algorithm to find a solution if one exists.",
      image: sudokusolver,
      link: "https://github.com/SrimanCode/Sudoku",
      tech: ["HTML", "CSS", "JS"],
    },
    {
      name: "Follow up - CalHack 10.0",
      description:
        '"Follow Up" creates a platform for therapists and patients to communicate after a traditional in-person session',
      link: "https://github.com/SrimanCode/our_project",
      image: followup,
      tech: ["HTML", "CSS", "JS", "Flask", "MySQL"],
    },
  ],
  mobile: [
    {
      name: "SettleInn",
      description:
        "An app that makes finding houses to buy/rent simpler and convenient.",
      image: settleinn,
      link: "https://github.com/AND-Group-Project/SettleInn-app",
      tech: ["Android Studio", "kotlin", "Zillow API"],
    },
    {
      name: "FootyNow",
      description:
        "FootyNow aims to keep soccer fans across the globe up dated on current soccer news including scores, team news and general press coverage over soccer teams.",
      image: footnow,
      link: "https://github.com/Pod-42/Capstone-Project",
      tech: ["Android Studio", "Football API", "Kotlin"],
    },
    {
      name: "SleepTracker",
      description:
        "SleepTracker is a health metrics app that allows users to track your daily sleep and also shows you your sleep time and quality chart.",
      image: sleeptracker,
      link: "https://github.com/SrimanCode/AND_Project6",
      tech: ["Android Studio", "Local DB", "Kotlin"],
    },
  ],
  desktop: [
    {
      name: "Maze and Path finding Algorithm",
      description:
        "Creates unique mazes and uses algorithm like BFS, DFS, and A* to traverse through the mage.",
      image: pathfinding,
      link: "https://github.com/SrimanCode/Maze-and-Path-Finding-Alogrithm",
      tech: ["Python", "Alogirthms", "BFS", "DFS", "A*"],
    },
  ],
};

function ProjectCategories() {
  const [activeCategory, setActiveCategory] = useState("web");
  const gridVariants = {
    initial: { opacity: 0, y: 30 },
    enter: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, staggerChildren: 0.1 },
    },
    exit: { opacity: 0, y: -30, transition: { duration: 0.5 } },
  };

  const boxVariants = {
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
    tap: {
      scale: 0.95,
      transition: { duration: 0.3 },
    },
  };

  return (
    <div className="w-full h-full">
      <div className="flex justify-center mt-10">
        <div className="flex flex-row items-center p-2 space-x-4 transition duration-300 ease-in-out shadow-lg rounded-2xl">
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setActiveCategory("web")}
            className={`cursor-pointer ${
              activeCategory === "web" ? "text-blue-500" : "text-gray-500"
            } font-bold text-[1.4em] p-3 hover:text-teal-500`}
          >
            Web Apps
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setActiveCategory("mobile")}
            className={`cursor-pointer ${
              activeCategory === "mobile" ? "text-blue-500" : "text-gray-500"
            } pl-7 font-bold text-[1.4em] p-3 hover:text-teal-500`}
          >
            Mobile Apps
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setActiveCategory("desktop")}
            className={`cursor-pointer ${
              activeCategory === "desktop" ? "text-blue-500" : "text-gray-500"
            } pl-7 font-bold text-[1.4em] p-3 hover:text-teal-500`}
          >
            Desktop Apps
          </motion.div>
        </div>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          className="grid grid-cols-1 gap-16 px-20 pt-5 md:grid-cols-2 auto-rows-fr lg:pl-48 lg:pr-48"
          key={activeCategory}
          variants={gridVariants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          {projects[activeCategory].map((project, index) => (
            <motion.a
              target="_blank"
              rel="noopener noreferrer"
              key={index}
              variants={boxVariants}
              whileHover="hover"
              whileTap="tap"
              className="block w-full h-full text-current no-underline"
            >
              <div
                className="flex flex-col justify-between h-full pb-4 duration-300 pb-4transition-shadow rounded-2xl hover:shadow-xl"
                style={{
                  boxShadow: "0px 0px 10px rgba(255, 255, 255, 0.5)",
                  hover: "0px 0px 10px rgba(255, 255, 255, 0.7)",
                }}
              >
                <div className="flex items-center justify-center flex-shrink-0 mb-4">
                  <img
                    className="object-contain w-full h-full bg-white rounded-lg max-h-40"
                    src={project.image}
                    alt={project.name}
                  />
                </div>
                <h1 className="flex justify-center text-xl font-semibold text-white">
                  {project.name}
                </h1>
                <p className="mt-2 text-base text-center text-white">
                  {project.description}
                </p>
                <div className="flex flex-wrap justify-center gap-2 mt-4">
                  {project.tech.map((tech, index) => (
                    <div
                      key={index}
                      className="flex items-center px-3 py-1 text-sm font-medium text-white transition-colors duration-300 bg-gray-700 rounded-full hover:bg-blue-600"
                    >
                      {techToIcon[tech] && (
                        <FontAwesomeIcon
                          icon={techToIcon[tech]}
                          className="mr-1"
                        />
                      )}
                      <span>{tech}</span>
                    </div>
                  ))}
                </div>
                <div className="flex justify-center gap-5 mt-4">
                  <AnimatedButton
                    button_name="View Code"
                    button_icon={faGithub}
                    button_link={project.link}
                    isLink={false}
                  />
                  <AnimatedButton
                    button_name="Live Demo"
                    button_icon={""}
                    button_link={project.demo}
                    isLink={false}
                  />
                </div>
              </div>
            </motion.a>
          ))}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default ProjectCategories;
