import React from "react";
import Typewriter from "typewriter-effect";
import Letsconnect from "./Letsconnect";
import WaterDropGrid from "../components/WaterDropGrid";
import "./styles/Home.css";
import "../App.css";
import { Application } from "@splinetool/runtime";
import { Button, Link } from "react-scroll";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef } from "react";
import { useEffect } from "react";
import {
  faFacebookF,
  faInstagram,
  faGithub,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faDownload,
  faEnvelope,
  faTimes,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { useScroll, useTransform } from "framer-motion";
import { useNavigate } from "react-router-dom";
import AnimatedButton from "../components/AnimatedButton";

const Home = () => {
  const [vantaEffect, setVantaEffect] = useState(null);
  const myRef = useRef(null);

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    const initializeVanta = async () => {
      await loadScript(
        "https://cdnjs.cloudflare.com/ajax/libs/three.js/r134/three.min.js"
      );
      await loadScript(
        "https://cdn.jsdelivr.net/npm/vanta/dist/vanta.birds.min.js"
      );

      if (!vantaEffect && myRef.current) {
        setVantaEffect(
          window.VANTA.BIRDS({
            el: myRef.current,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.0,
            minWidth: 200.0,
            scale: 1.0,
            scaleMobile: 1.0,
            backgroundColor: 0x000000,
            color1: 0xf5f5f5,
            color2: 0x00102,
            colorMode: "lerp",
            birdSize: 1.6,
            separation: 44.0,
            alignment: 38.0,
            cohesion: 19.0,
            quantity: 3.0,
          })
        );
      }
    };

    initializeVanta();

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  const { scrollYProgress } = useScroll();
  const x = useTransform(scrollYProgress, [0, 1], [0, -600]);

  return (
    <div
      ref={myRef}
      id="Home"
      className="relative flex flex-row items-center justify-center min-h-screen text-white "
    >
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <div className="py-10 text-center">
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, scale: 1.1 }}
            transition={{
              ease: "backInOut",
              delay: 0.4,
              duration: 1.7,
              type: "spring",
            }}
            className="mb-4 text-5xl font-bold md:text-7xl"
          >
            👋 Hey there, I'm <span className="text-blue-500">Sriman</span>
          </motion.h1>
          <div className="flex mb-4 text-3xl font-extrabold md:ml-20 md:flex-row md:text-5xl text-cyan-500">
            <motion.span
              className="pr-2 text-white"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                ease: "backInOut",
                delay: 0.4,
                duration: 1.7,
                type: "spring",
              }}
            >
              Aspiring
            </motion.span>
            <div></div>
            <Typewriter
              options={{
                strings: [
                  "Software Engineer",
                  "Android Developer",
                  "Backend Developer",
                ],
                autoStart: true,
                loop: true,
                pauseFor: 2000,
                speed: 50,
              }}
            />
          </div>
          <div className="flex flex-col items-center space-y-4">
            <div className="flex flex-row justify-center gap-4 pt-8 md:flex-row lg:flex-row lg:gap-8">
              <AnimatedButton
                button_name="Download CV"
                button_icon={faDownload}
                button_link="/download-cv"
                isLink={false}
              />

              <AnimatedButton
                button_name="Contact"
                button_icon={faEnvelope}
                button_link="Connect"
                isLink={true}
              />

              <AnimatedButton
                button_name="Resume"
                button_icon={faFile}
                button_link="https://drive.google.com/file/d/1kdw77VGUs9Wxq0XHl2VFGE7LlzOAUxwD/view?usp=sharing"
                isLink={false}
              />
            </div>
            <div className="flex gap-4 pt-3 space-x-6">
              <a
                href="https://facebook.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className="text-3xl text-white hover:text-blue-600"
                />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="text-3xl text-white hover:text-pink-500"
                />
              </a>
              <a
                href="https://twitter.com/srimanp201"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="text-3xl text-white hover:text-blue-400"
                />
              </a>
              <a
                href="https://github.com/srimancode"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faGithub}
                  className="text-3xl text-white hover:text-gray-700"
                />
              </a>
              <a
                href="https://www.linkedin.com/in/srimanujhanthachhen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  className="text-3xl text-white hover:text-blue-700"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
