import React, { useState, useEffect, useRef } from "react";
import { Link, Element } from "react-scroll";
import Home from "../pages/Home";
import About from "../pages/About";
import Portfolio from "../pages/Portfolio";
import Letsconnect from "../pages/Letsconnect";
import Skills from "../pages/Skills";
import { motion, useScroll, useSpring } from "framer-motion";
import { gsap } from "gsap";
import Logo from "../svg/logo.svg";

const Navigation = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isTop, setIsTop] = useState(true);
  const navItems = useRef([]);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      setLastScrollY(window.scrollY);
      setIsTop(window.scrollY === 0);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  // GSAP Animation for Hover Effect
  useEffect(() => {
    navItems.current.forEach((item) => {
      gsap.fromTo(
        item,
        { scale: 1 },
        {
          scale: 1.1,
          duration: 0.3,
          ease: "power1.inOut",
          paused: true,
        }
      );

      item.addEventListener("mouseenter", () => {
        gsap.to(item, { scale: 1.1, duration: 0.3, ease: "power1.inOut" });
      });

      item.addEventListener("mouseleave", () => {
        gsap.to(item, { scale: 1, duration: 0.3, ease: "power1.inOut" });
      });
    });
  }, []);

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 20,
    restDelta: 0.001,
  });

  return (
    <div>
      <nav
        className={`fixed z-10 w-full bg-opacity-95 backdrop-blur-sm transition-transform duration-300 
        }`}
      >
        <div className="z-10 flex-row hidden p-4 md:flex md:justify-center">
          <div
            className={`flex flex-row justify-between p-3 rounded-xl ${
              isTop ? "bg-opacity-90" : "bg-gray-200 bg-opacity-85"
            }`}
          >
            <ul className="hidden gap-10 pr-10 md:flex">
              {["home", "about", "Skills", "portfolio", "Connect"].map(
                (link, index) => (
                  <li key={link} ref={(el) => (navItems.current[index] = el)}>
                    <Link
                      to={link}
                      className={`relative ${
                        isTop ? "text-white" : "text-black"
                      } pl-6 text-xl font-bold hover:text-sky-700`}
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      onSetActive={() => setActiveLink(link)}
                    >
                      {link.charAt(0).toUpperCase() + link.slice(1)}
                      <motion.div
                        className="flex ml-5 h-[4px] bg-sky-700"
                        initial={{ scaleX: 0, width: "0%" }}
                        animate={{
                          scaleX: activeLink === link ? 1 : 0,
                          width: activeLink === link ? "85%" : "0%",
                        }}
                        transition={{ duration: 0.2, ease: "easeInOut" }}
                      />
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </nav>
      <Element name="home" className="element">
        <Home />
      </Element>
      <Element name="about" className="element">
        <About />
      </Element>
      <Element name="Skills" className="element">
        <Skills />
      </Element>
      <Element name="portfolio" className="element">
        <Portfolio />
      </Element>
      <Element name="Connect" className="overflow-hidden element">
        <Letsconnect />
      </Element>
    </div>
  );
};

export default Navigation;
