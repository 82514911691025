import Navigation from "../nav/nav";
import Footer from "./Footer";
import { motion } from "framer-motion";

import ProjectCategories from "../components/ProjectCategories";
const Portfolio = () => {
  return (
    <div className="min-h-screen bg-black">
      <h1 className="pt-10 text-6xl font-bold text-center text-white md:text-7xl">
        My <span className="px-3 text-indigo-700">Portfolio</span> section
      </h1>

      <ProjectCategories />
    </div>
  );
};

export default Portfolio;
